import { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import moment from "moment";
const SOCKET_URL = "https://app.postaam.com"; // Replace with your server's IP and port
let socket: Socket | undefined;

// Initiate socket connection with the token and proper configuration
export const initiateSocketConnection = (token: string) => {
    socket = io(SOCKET_URL, {
        reconnection: true,
        autoConnect: true,
        reconnectionDelay: 500,
        reconnectionAttempts: Infinity,
        extraHeaders: {
            Authorization: `Bearer ${token}`,
        },
    });
    console.log("Connecting socket...");
};

// Disconnect the socket connection if it exists
export const disconnectSocket = () => {
    console.log("Disconnecting socket...");
    if (socket) socket.disconnect();
};

// Subscribe to chat events, handle incoming messages and execute callback with the received data
export const subscribeToChat = async (callback: (data: any) => void, chatId: string, roomId: string) => {
    if (!socket) return;

    socket.emit("joinChat", { chatId, room: roomId, fromGig: true });

    socket.on("message", (data) => {
        console.log("New chat message received!");
        callback(data);
    });
};

// Send a chat message event through the socket
export const sendMessage = (message: string, userId: string, gigId: string, chatId: string) => {
    if (socket) {
        socket.emit("chatMessage", message, userId, gigId, chatId);
    }
};

// Send media attachments through the socket
export const sendAttachments = async (medias: any) => {
    if (socket) {
        socket.emit("upload", { medias });
    }
};

// Load more chat messages with pagination
export const loadMore = async (page: number, chatId: string) => {
    if (socket) {
        socket.emit("loadMore", { page, chatId });
    }
};
type GigChatMessageProps = {
    chatId: string,
    roomId: string,
    gigId: string
    user: {
        fullname: string,
        profileFile: string
    }
}
type MessageProps = {
    createdAt: string,
    fileType: string,
    message: string,
    receivedBy: string,
    receivedByHandle: string,
    sendBy: string,
    sendByHandle: string,
}
const GigChatMessage = ({ chatId, roomId, gigId, user }: GigChatMessageProps) => {
    const [typeMessage, setTypeMessage] = useState("");
    const [messages, setMessages] = useState<MessageProps[]>([])
    const [sendLoading, setSendLoading] = useState(false);
    useEffect(() => {
        (async () => {
            initiateSocketConnection(
                JSON.parse(localStorage.getItem("token")!).token
            );


            await subscribeToChat(
                (data) => {
                    if (data.results)
                        setMessages((prevMessages) => [...prevMessages!, ...data.results]);
                    if (data.message) {
                        setSendLoading(true)
                        setMessages((prevMessages) => [data, ...prevMessages!]);
                        setSendLoading(false);

                    }
                },
                chatId,
                roomId
            );
        })();

        return () => {
            disconnectSocket();
        };
    }, []);
    const handleSendMessage = (data: string) => {
        sendMessage(data, roomId, gigId, chatId);
        setTypeMessage("");
    };

    const bottomRef = useRef<HTMLDivElement | null>(null);

    // Scroll to the bottom whenever messages change
    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return (
        <div >
            <div className="h-[550px] overflow-y-scroll hide-scrollbar bg-white border">
                <ul className="flex flex-col p-10 justify-end w-full gap-3">
                    {!sendLoading &&
                        messages
                            .map((ele, index) => (
                                <li
                                    className={`flex ${ele.sendByHandle === gigId ? 'justify-end' : 'justify-start'
                                        }`}
                                    key={index}
                                >
                                    <div>

                                    </div>
                                    {
                                        ele.sendByHandle != gigId && <div className="w-12 h-12  mr-2">
                                            <img src={user.profileFile} alt="" className="object-cover w-full h-full overflow-hidden rounded-full" />
                                        </div>
                                    }
                                    <div
                                        className={`w-1/2 flex flex-col ${ele.sendByHandle === gigId
                                            ? 'bg-blue-700 text-white'
                                            : 'bg-gray-300'
                                            } p-3 rounded-3xl`}
                                    >

                                        {ele.fileType === 'text' && <span>{ele.message}</span>}
                                        {ele.fileType === 'image' && (
                                            <img src={ele.message} width={200} height={200} />
                                        )}
                                        {ele.fileType === 'video' && (
                                            <video src={ele.message} width={200} height={200} controls />
                                        )}
                                        <small>        {moment(moment.utc(ele.createdAt)).fromNow()}</small>
                                    </div>

                                </li>
                            ))
                            .reverse()}

                    {/* Empty div that acts as the scroll target */}
                    <div ref={bottomRef} />
                </ul>
            </div>

            <div className="flex bg-white p-5 py-8">
                <input
                    type="text"
                    placeholder="Send message"
                    value={typeMessage}
                    onChange={(e) => setTypeMessage(e.target.value)}
                    style={{ padding: 10, border: "1px solid gray", borderRadius: 30 }}
                    className="w-full rounded-3xl"
                />
                <button onClick={() => handleSendMessage(typeMessage)} className="bg-blue-700 text-white px-10 rounded-3xl ml-4">
                    Send
                </button>
            </div>
        </div>

    )
}

export default GigChatMessage